<template>
  <div class="violations-modal-view">
    <modal-view-header
      v-loading="loading"
      :source="source"
      @edit="$emit('mode', 'edit')"
      @copy="$emit('mode', 'copy', source.id)"
      @export="exportViolation"
      @delete="deleteRegisterItem"
    />

    <r-tabs
      @input="(e) => viewTab = e"
    >
      <r-tab
        v-for="tab in tabs"
        :id="tab.label"
        :key="tab.label"
        :name="tab.title"
        :active="viewTab === tab"
      />
    </r-tabs>

    <div :class="['violations-modal-view__wrapper', { disabledTab }]">
      <modal-event-tip v-if="source.event_id && viewTab !== 'filesComments'" />

      <modal-event-card
        v-if="source.event_id && viewTab === 'main'"
        :source="source"
      />

      <attributes-list
        v-if="viewTab === 'main'"
        :model="violationTypeField"
        :source="source"
        :related="source.related"
      />

      <attributes-list
        v-if="viewTab !== 'filesComments'"
        :model="tabFields"
        :source="source"
        :related="source.related"
        :mark-items="markItems"
      />
      <files-comments
        v-if="viewTab === 'filesComments'"
        :id="activeApplicationId"
        :source_id="source_id"
        column
        container
      />
    </div>
  </div>
</template>

<script>
import { saveAs } from 'file-saver'
import { fields, sources, tabs, violationTypeField } from '../../../configs'

export default {
  components: {
    modalViewHeader: () => import('./modal-view-header'),
    modalEventCard: () => import('../modal-event-card'),
    modalEventTip: () => import('../modal-event-tip'),
    attributesList: () => import('@/components/globals/r-modal-elements/attributes-list'),
    filesComments: () => import('@/components/files-comments/files-comments')
  },
  props: {
    source_id: {
      type: String,
      required: true
    },
    source: {
      type: Object,
      required: true
    },
    markItems: {
      type: Array,
      default: () => []
    },
    noViolator: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      hasChanges: false,
      viewTab: 'main',
      violationTypeField,
      tabs
    }
  },
  computed: {
    activeApplicationId() {
      return this.source?.id
    },
    tabFields() {
      return fields.filter(e => e.tab === this.viewTab)
    },
    disabledTab() {
      if (!this.noViolator) return false
      if (this.viewTab === 'violator') return true
      return false
    }
  },
  methods: {
    async deleteRegisterItem() {
      const { source, source_id, $store } = this

      try {
        await $store.dispatch('POST_REQUEST', {
          url: `objectInfo/${source_id}`,
          data: { id: source.id, disabled: true }
        })
      } catch (e) {
        console.warn(e)
      } finally {
        $store.commit('BDD_SET_FIELD', {
          field: sources.updateField,
          value: true
        })
        $store.commit('CLOSE_MODAL_WINDOW')
      }
    },
    async exportViolation() {
      const { source } = this

      try {
        this.loading = true

        const { data } = await this.$store.dispatch('POST_BLOB_REQUEST', {
          url: 'DocxTemplater',
          data: { type: 'violation_cart', data: { id: source.id } }
        })

        saveAs(data, 'violation-card')
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scope>
.violations-modal-view {
  display: grid;
  grid-gap: 1.5rem;

  &__wrapper {
    display: grid;
    grid-gap: 1.5rem;

    &.disabledTab {
      position: relative;
      opacity: 0.2;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
